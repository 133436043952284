import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link } from 'gatsby'
import '../sass/style.scss';
import logo from '../images/yr_logo.png'
import Logo from '../components/logo'

class Layout extends React.Component {
  state = {
   date: Date.now()
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({date: Date.now()})
    }, 1000 )
  }

  render() {
    const { location, children, color } = this.props
    const { date } = this.state 
    return (
      <div className="wrapper" style={{
        backgroundImage: `url(${this.props.bg})`,
        color: this.props.color
        }}>
        <header className="header">
          <div className="time">{date}</div>
          <div className="back-link"><AniLink fade duration={1} to="/?back">Rooms</AniLink></div>
        </header>

        <main 
          className={`container ${location.pathname === '/' ? 'home' : ''}`}
        >
          {children}
        </main>
        <div className={`footer ${location.pathname === '/' ? 'home-footer' : ''}`}>
          <a href="https://year0001.com/" target="_blank">
            <Logo fill={color}></Logo>
          </a>
          <a className="smartlink" href="http://yr1.se/dozakh">Dozakh: All Lovers Hell (Expanded) — Out Now</a>
        </div>
      </div>
    )
  }
}

export default Layout
