import React from "react"
import divider from '../images/divider_img.png'
import rehypeReact from "rehype-react"
import Icon from '../components/icon'

const renderAst = new rehypeReact({
    createElement: React.createElement,
  }).Compiler

class Post extends React.Component {

  render() {
    const { frontmatter, htmlAst, color} = this.props
    const { title } = frontmatter
    return (
        <div className="post">
            <div className="post__title">
                <div>{title}</div>
            </div>
            <div className="post__body">
                {renderAst(htmlAst)}
            </div>
            <div className="post__devider">
                {/* <img style={{filter: color === '#fff' ? 'invert(1)' : 'none'}} src={divider} alt=""/> */}
                <Icon fill={color}></Icon>
                <hr style={{background: color}}/>
            </div> 
        </div>
    )
  }
}

export default Post
