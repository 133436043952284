import * as React from "react"

function Icon(props) {
  return (
    <svg viewBox="0 0 180 180" {...props}>
      <path d="M72.19 145.15c-5.16 0-10.33.19-15.48-.08-2.41-.13-5.15-.56-7.08-1.84-8.17-5.41-13.67-12.3-12.74-23.07.79-9.09.76-18.26.9-27.4.14-8.66.09-17.31-.01-25.97-.06-5.41-1.17-6.46-6.47-6.51-6.16-.06-12.33.06-18.48-.1-1.33-.04-2.63-.95-3.94-1.46.57-1.31.83-2.93 1.76-3.89 5.78-5.99 11.66-11.88 17.63-17.69 2.66-2.58 4.7-2.52 7.33.15 7 7.12 14.09 14.17 20.71 21.63 1.77 2 2.69 5.35 2.8 8.13.35 8.98.13 17.98.13 26.97 0 1.33-.13 2.69.06 3.99.19 1.25.72 2.46 1.09 3.68 1.08-.61 2.34-1.03 3.21-1.86 8.19-7.81 16.32-15.67 24.44-23.56 3.94-3.83 7.87-7.67 11.69-11.62 2.6-2.68 5.6-3.97 9.36-3.86 4.83.15 9.66-.04 14.48.13 1.19.04 2.97.63 3.34 1.47.37.84-.55 2.38-1.16 3.47-.47.84-1.34 1.47-2.04 2.18-13.16 13.44-26.32 26.87-39.48 40.31-.93.95-1.88 1.9-2.72 2.92-1.26 1.53-1.04 3.26.98 3.21 3.89-.1 7.92-.37 11.59-1.54 2.35-.75 4.28-3.08 6.16-4.94 11.49-11.35 22.9-22.78 34.39-34.13 2.6-2.57 5.34-5.01 8.19-7.3 1.1-.88 2.66-1.61 4.03-1.65 6.82-.17 13.65-.13 20.48-.06.91.01 1.81.57 2.71.87-.37 1.01-.49 2.24-1.15 2.99-3.06 3.5-6.18 6.97-9.46 10.28-17 17.15-34.07 34.22-51.06 51.37-3.39 3.42-6.54 7.09-9.89 10.56-2.79 2.88-6.22 4.31-10.31 4.23-5.33-.09-10.66-.01-15.99-.01z" />
    </svg>
  )
}

export default Icon